<template>
  <div class="share-box">
    点击分享：
    <a-popover placement="bottom"   @mouseenter="showCode()" >
      <div slot="content" class="wx-code-div">
        <div id="qrcode" class="qrcode" ref="qrCodeUrl" style="width: 140px;height: 140px">
        </div>
      </div>
      <img alt="图片" type="primary" src="@/assets/img/weixin.png"/>
    </a-popover>
    <img alt="图片" @click="shareToMicroblog()" src="@/assets/img/activity/weibo.png" />
    <img alt="图片" @click="shareToKj()" src="@/assets/img/activity/kongjian.png" />
    <img alt="图片" @click="shareToQQ()" src="@/assets/img/activity/QQ.png" />
  </div>
</template>

<script>
import QRCode from "qrcodejs2"
import {getH5path} from "@/api/common"
let url = document.location; //获取当前网页地址
export default {
  props: ["val"],
  data(){
    return {
      h5path:"",
      wxUrl:""
    };
  },
  mounted() {
    this.wxUrl = "";
    this.getWxH5path()
  },
  methods: {
    getWxH5path(){
      getH5path().then(res=>{
        this.h5path = res.url
      });
    },
    showCode() {
      setTimeout(() => {
        this.shareToWeixin()
      }, 300)
    },
    shareToWeixin() {
      if (this.val.id) {
        this.wxUrl = this.h5path+"/product/productInfo?id=" + this.val.id;
        if (url.pathname === "/replace/productInfo/dev") {
          this.wxUrl += "&type=dev";
        } else if (url.pathname === "/replace/productInfo/soc") {
          this.wxUrl += "&type=soc";
        } else if (url.pathname === "/replace/info") {
          this.wxUrl = this.h5path+"/product/iotInfo?id=" + this.val.id;
        }
        this.$nextTick(() => {
          document.getElementById("qrcode").innerHTML = "";
          new QRCode(this.$refs.qrCodeUrl, {
            text: this.wxUrl, // 需要转换为二维码的内容
            width: 140,
            height: 140,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
          })
        })
      }
    },
    shareToQQ() {
      var _shareUrl = "https://connect.qq.com/widget/shareqq/index.html?";
      _shareUrl += "url=" + encodeURIComponent(url);
      _shareUrl += "&sharesource=qzone";
      _shareUrl += "&title=" + encodeURIComponent(this.val.name);
      window.open(_shareUrl, "_blank");
    },
    shareToMicroblog() {
      var sharesinastring =
        "http://service.weibo.com/share/share.php?title=" +
        encodeURIComponent("「" + this.val.name + "」" + " 点这里" + url);
      window.open(sharesinastring, "_blank");
    },
    shareToKj() {
      let {val} = this
      window.open(`https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${encodeURIComponent(url)}&sharesource=qzone&title=${val.name}`)
    }
  },
};
</script>

<style lang="scss" scoped>
.share-box {
  display: flex;
  align-items: center;

  > img {
    cursor: pointer;
    margin-left: 10px;
  }
}
</style>
